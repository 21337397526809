import { useEffect, useState } from 'react';
import Inputmask from 'inputmask';

import { telMask, submitRequestForm } from '../methods';

import './style.css';


type SubmitRequestHeaderFormProps = {
  calcReport?: object;
}

export default function SubmitRequestHeaderForm(props: SubmitRequestHeaderFormProps) {
  // console.log('vertical form props.calcReport', props.calcReport);

  const [name, setName] = useState('');
  const [tel, setTel] = useState('');
  const [org, setOrg] = useState('');

  useEffect(() => {
    const phoneInputElem = document.getElementById('header-form-tel') as HTMLElement;
    Inputmask(telMask, { showMaskOnHover: false }).mask(phoneInputElem);
    return () => Inputmask.remove(phoneInputElem);
  })

  return (
    <div className="App-header-submit-request-container">
      <h2>Оставить заявку</h2>
      <span>на бесплатную консультацию</span>
      <form onSubmit={event => {
        event.preventDefault();
        submitRequestForm({name, tel, org, calcReport: props.calcReport});
      }}>
        <label htmlFor="header-form-name" className="App-header-submit-request-form-item">
          <input type="text" name="name" id="header-form-name" value={name} onChange={e => setName(e.target.value)} required />
          <span>ФИО*</span>
        </label>
        <label htmlFor="header-form-tel" className="App-header-submit-request-form-item">
          <input type="tel" name="tel" id="header-form-tel" value={tel} onChange={e => setTel(e.target.value)} required />
          <span>Ваш телефон*</span>
        </label>
        <label htmlFor="header-form-org" className="App-header-submit-request-form-item">
          <input type="text" name="org" id="header-form-org" value={org} onChange={e => setOrg(e.target.value)} required />
          <span>Название организации*</span>
        </label>
        <div className="note">
          <div className="App-question-mark">
            <svg width="16" height="16"><circle cx="8" cy="8" r="8" fill="white" /></svg>
            <div style={{ color: '#BF9A6C' }}>?</div>
          </div>
          <span>Дополнительные материалы можете<br/>отправить на почту info@profil-buh.ru</span>
        </div>
        {props.calcReport && Object.keys(props.calcReport).length ?
          <div className="note">
            <div className="App-question-mark">
              <svg width="16" height="16"><circle cx="8" cy="8" r="8" fill="white" /></svg>
              <div style={{ color: '#BF9A6C' }}>:)</div>
            </div>
            <span>Мы учтём ваши пожелания по проведённым<br/>расчётам!</span>
          </div>
        : null}
        <input type="submit" value="Оставить заявку" />
      </form>
    </div>
  );
}
