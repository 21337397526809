import React, { useEffect, useRef, useState } from 'react';
import Inputmask from 'inputmask';

import footerFormBg from '../../../img/footer-form-bg.jpg';

import { telMask, submitRequestForm } from '../methods';

import './style.css';


export default function SubmitRequestFooterForm() {
  const [size, setSize] = useState(window.innerWidth);

  const [name, setName] = useState('');
  const [tel, setTel] = useState('');
  const [org, setOrg] = useState('');
  const [descr, setDescr] = useState('');

  const textareaLabel = useRef<HTMLSpanElement>(null);

  const updateMedia = () => {
    setSize(window.innerWidth);
  };

  useEffect(() => {
    const phoneInputElem = document.getElementById('footer-form-tel') as HTMLElement;
    Inputmask(telMask, { showMaskOnHover: false }).mask(phoneInputElem);
    window.addEventListener('resize', updateMedia);
    return () => {
      Inputmask.remove(phoneInputElem);
      window.removeEventListener('resize', updateMedia);
    };
  });

  return (
    <div className="App-footer-submit-request">
      {(size >= 768) ? <img src={footerFormBg} alt="footer-form-bg"/> : null}
      <div>
        <div className="App-footer-submit-request-title">
          <h2>Оставить заявку</h2>
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start'
          }}>
            <div className="App-question-mark">
              <svg width="16" height="16"><circle cx="8" cy="8" r="8" fill="white" /></svg>
              <div style={{ color: '#BF9A6C' }}>?</div>
            </div>
            <span style={{
              marginLeft: '1em',
              fontSize: '0.8em'
            }}>Дополнительные материалы можете отправить на почту info@profil-buh.ru</span>
          </div>
        </div>
        <form onSubmit={event => {
          event.preventDefault();
          submitRequestForm({name, tel, org, descr});
        }}>
          <div className="App-footer-submit-request-form">
            <div>
              <label htmlFor="footer-form-name" className="App-footer-submit-request-form-item">
                <input type="text" name="name" id="footer-form-name" value={name} onChange={e => setName(e.target.value)} required />
                <span>ФИО*</span>
              </label>
              <label htmlFor="footer-form-tel" className="App-footer-submit-request-form-item">
                <input type="tel" name="tel" id="footer-form-tel" value={tel} onChange={e => setTel(e.target.value)} required />
                <span>Ваш телефон*</span>
              </label>
              <label htmlFor="footer-form-org" className="App-footer-submit-request-form-item">
                <input type="text" name="org" id="footer-form-org" value={org} onChange={e => setOrg(e.target.value)} required />
                <span>Название организации*</span>
              </label>
            </div>
            <div>
              <label htmlFor="footer-form-desc" className="App-footer-submit-request-form-item">
                <textarea rows={6} name="descr" id="footer-form-desc" value={descr} onChange={e => {
                  if (e.target.value && textareaLabel.current) {
                    textareaLabel.current.classList.add('not-empty');
                  } else if (!e.target.value && textareaLabel.current) {
                    textareaLabel.current.classList.remove('not-empty');
                  }
                  setDescr(e.target.value);
                }} />
                <span ref={textareaLabel}>{size < 768 ? 'Краткое описание услуг' : 'Краткое описание организации или требуемых услуг'}</span>
              </label>
              <div className="App-footer-submit-request-form-submit">
                <div className="App-footer-submit-request-form-submit-checkbox">
                  <input type="checkbox" id="footer-form-agree" required />
                  <label htmlFor="footer-form-agree">Вы соглашаетесь с обработкой<br/>персональных данных (<a href="#">ознакомиться</a>)</label>
                </div>
                <input type="submit" value="Оставить заявку" />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
