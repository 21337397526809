import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import headerImg from './img/postanovka-buhucheta.jpeg';
import checkImg from './check.svg';
import nextImg from './next.svg';

import { PageProps, SubmitRequest, useMeta } from './common';

import './style.css';


export default function PostanovkaBuhucheta(props: PageProps) {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useMeta('Постановка бухгалтерского учета компании в Сыктывкаре, Республике Коми', {
    description: 'Профессиональная постановка бухгалтерского учета в организации с нуля или при изменении сферы деятельности. Возможна постановка бухгалтерского учета компании любой формы собственности. Постановка бухучета займет до 3 рабочих дней, цена от 5 000 руб.',
    keywords: 'постановка бухгалтерского учета, постановка бухучета, постановка бухгалтерского учета в организации, постановка бухгалтерского учета компании'
  });

  return (
    <div className="our-service-page">
      <HashLink to="/#services" className="goback">{`< Вернуться`}</HashLink>
      <div className="header">
        <div>
          <h1>Постановка бухгалтерского учета в организации</h1>
          <span>Защитите бизнес от рисков</span>
          <p style={{ marginTop: '2em' }}>Возможна постановка бухгалтерского учета при открытии компании и при смене направления деятельности. В любом случае вы получите готовую схему, при которой затраты на обработку первичной документации будут минимальными, а контроль за деятельностью специалистов окажется максимально простым. Также предложенная методология позволит законным образом сократить налоговую нагрузку.</p>
        </div>
        <img src={headerImg} alt="stock" />
      </div>
      <div className="highlights">
        <div>
          <img src={checkImg} alt="check"/>
          <p>Сотрудничество с организациями всех форм собственности и ИП</p>
        </div>
        <div>
          <img src={checkImg} alt="check"/>
          <p>Решение любых задач – в штате специалисты с опытом от 15 лет</p>
        </div>
        <div>
          <img src={checkImg} alt="check"/>
          <p>Заключение договора на предоставление услуг</p>
        </div>
        <div>
          <img src={checkImg} alt="check"/>
          <p>Взаимодействие по телефону, email и через онлайн-платформы</p>
        </div>
      </div>
      <SubmitRequest
        text="Закажите постановку бухгалтерского учета компании – приступим к работе в течение 24 часов"
        style={{
          marginTop: '2em',
          marginBottom: '6em'
        }}
        onClick={() => props.setSubmitRequestPopupIsOpen(true)}
      />
      <h2 className="title">Цена постановки бухучета от 5 000 рублей</h2>
      <p className="subtitle">Фиксированные тарифы – не меняются в процессе решения задач</p>
      <p className="text">Стоимость услуг напрямую зависит от размера компании, направления деятельности и других нюансов. Точная сумма будет известна до начала работ – рассчитаем ее после того, как обсудим с вами детали сотрудничества.</p>
      <h2 className="title">Срок постановки бухгалтерского учета компании до 3 рабочих дней</h2>
      <p className="subtitle">Как обычно работают наши специалисты:</p>
      <div className="highlights">
        <div>
          <img src={nextImg} alt="next"/>
          <p>Анализ деятельности предприятия и текущего состояния учета</p>
        </div>
        <div>
          <img src={nextImg} alt="next"/>
          <p>Разработка предложений и их согласование с заказчиком</p>
        </div>
        <div>
          <img src={nextImg} alt="next"/>
          <p>Постановка бухучета по ранее подготовленному нами плану</p>
        </div>
        <div>
          <img src={nextImg} alt="next"/>
          <p>Внедрение готовой системы – включая обучение персонала</p>
        </div>
      </div>
      <SubmitRequest
        text="Узнайте точную цену и сроки постановки бухучета – предварительная консультация является бесплатной"
        style={{
          marginTop: '5em',
          marginBottom: '5em'
        }}
        onClick={() => props.setSubmitRequestPopupIsOpen(true)}
      />
    </div>
  );
}
