import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import headerImg from './img/vedenie-uchastkov-buhgalterii.jpeg';
import checkImg from './check.svg';

import { PageProps, SubmitRequest, useMeta } from './common';

import './style.css';


export default function VedenieUchastkovBuhgalterii(props: PageProps) {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useMeta('Ведение бухгалтерского учета – стоимость от 1 000 руб./мес.', {
    description: 'Частичное и полное ведение бухгалтерского учета в Сыктывкаре и Республике Коми. Стоимость ведения бухгалтерского учета от 1 000 руб. – расчет за 30 минут. Выполняем ведение учета любой сложности. Оказываем услуги по ведению бухгалтерского учета с 2017 г.',
    keywords: 'ведение бухгалтерского учета, полное ведение бухгалтерского учета, ведение бухучета, услуги по ведению бухгалтерского учета, ведение бухгалтерского учета стоимость'
  });

  return (
    <div className="our-service-page">
      <HashLink to="/#services" className="goback">{`< Вернуться`}</HashLink>
      <div className="header">
        <div>
          <h1>Услуги ведения бухгалтерского учета</h1>
          <span>Индивидуальный тариф – только нужные вам работы</span>
          <p>Закажите ведение бухучета на определенных участках – сократите расходы на сопровождение деятельности своего предприятия без риска получить штраф от налоговых органов. Обсудим с вами детали и предложим индивидуальный план работ.</p>
        </div>
        <img src={headerImg} alt="stock" />
      </div>
      <div className="highlights">
        <div>
          <img src={checkImg} alt="check"/>
          <p>Индивидуальный подход к каждой задаче – получайте качественные услуги по ведению учета</p>
        </div>
        <div>
          <img src={checkImg} alt="check"/>
          <p>Гарантированная конфиденциальность – будьте уверены в безопасности данных</p>
        </div>
        <div>
          <img src={checkImg} alt="check"/>
          <p>Материальная ответственность – получайте отчеты в срок и в требующемся объеме</p>
        </div>
        <div>
          <img src={checkImg} alt="check"/>
          <p>Удобное взаимодействие со специалистами – консультируйтесь, когда это необходимо</p>
        </div>
      </div>
      <SubmitRequest
        text="Обсудите ведение бухучета – сразу рассчитаем примерную цену оказания услуг"
        style={{
          marginTop: '2em',
          marginBottom: '6em'
        }}
        onClick={() => props.setSubmitRequestPopupIsOpen(true)}
      />
      <h2 className="title">Стоимость ведения бухгалтерского учета от 1 000 рублей в месяц</h2>
      <p className="subtitle">Сотрудничаем с физическими и юридическими лицами, индивидуальными предпринимателями</p>
      <p className="text">Итоговая цена будет известна до момента подписания договора. Здесь указана стоимость отдельных работ, чтобы вы смогли сориентироваться.</p>
      <h2 className="title">Частые вопросы о ведение бухучета</h2>
      <p className="subtitle">Полезная информация</p>
      <p className="question">Вы занимаетесь полным ведением бухгалтерского учета?</p>
      <p className="text">Да, нам можно передать бухгалтерию на аутсорсинг – предусмотрен бесплатный тестовый период на 14 дней. Смотрите более подробную информацию на этой странице</p>
      <p className="question">От чего зависит стоимость услуг по ведению бухгалтерского учета?</p>
      <p className="text">От количества сотрудников, налогового режима, количества операций по банковской выписке и прочих факторов. Оставьте заявку – узнаем у вас детали, после чего подготовим коммерческое предложение и подробно расскажем обо всех нюансах.</p>
      <SubmitRequest
        text="Закажите ведение бухучета – наши специалисты всегда на связи с клиентами"
        style={{
          marginTop: '5em',
          marginBottom: '5em'
        }}
        onClick={() => props.setSubmitRequestPopupIsOpen(true)}
      />
    </div>
  );
}
