import React from 'react';

import SubmitRequestHeaderForm from '../forms/vertical/src';

import '../../util/modal.css'


type SubmitRequestPopupProps = {
  onClose?: () => void;
  calcReport?: object;
}
export default function SubmitRequestPopup(props: SubmitRequestPopupProps) {
  return (
    <div id="submit-request-popup" className="modal" style={{ display: 'block' }}>
      <div className="modal-content">
        <div className="modal-close" onClick={e => {
          if (typeof props.onClose === 'function') {
            props.onClose();
          }
        }}>
          <span className="modal-close-text">Закрыть</span>
          <span className="modal-close-cross">&times;</span>
        </div>
        <SubmitRequestHeaderForm calcReport={props.calcReport} />
      </div>
    </div>
  );
}
