import { useEffect, useState } from 'react';

import Pagination from '../util/pagination/src';

import img0 from './img/0.jpg';
import img1 from './img/1.jpg';
import img2 from './img/2.jpg';
import img3 from './img/3.jpg';
import img4 from './img/4.jpg';
import img5 from './img/5.jpg';

import './style.css';


interface WhatYouGetItem {
  title: string,
  text: string,
  fileName: string
}

const whatYouGetModel: Array<WhatYouGetItem> = [{
  title: "Бухгалтерия под ключ",
  text: `- Удобные способы и график оплаты услуг\n
         - Система скидок и привелегий\n
         - Бонусы постоянным клиентам и вашим друзьям\n
         - Каждый 7 месяц услуг бесплатно`,
  fileName: img0
}, {
  title: "Расчёт зарплаты сотрудников",
  text: "",
  fileName: img1
}, {
  title: "Ведение 1С бухгалтерии",
  text: "",
  fileName: img2
}, {
  title: "Налоговое консультирование",
  text: "",
  fileName: img3
}, {
  title: "Формирование учётной документации",
  text: "",
  fileName: img4
}, {
  title: "Подготовка и сдача отчётов в ПФР И ФНС",
  text: "",
  fileName: img5
}];


export default function WhatYouGet() {
  const scrollIndexMin = 1;
  const numSteps = 5;

  const [scrollIndex, setScrollIndex] = useState(scrollIndexMin);
  const [size, setSize] = useState(window.innerWidth);

  const updateMedia = () => {
    setSize(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  });

  const items = whatYouGetModel.map((wyg, idx) =>
    <div key={idx} className="hvr-float">
      <img src={wyg.fileName} alt={wyg.title} />
      <div>
        <h2>{'0' + (idx + scrollIndexMin).toString()}</h2>
        <h3>{wyg.title}</h3>
        <div className="App-page2-wyg-gallery-points">{wyg.text.split('\n').map((line, idx) => <p key={idx}>{line}</p>)}</div>
      </div>
      <svg width={size >= 768 ? 380 : 380*0.9*0.9} height="160">
        <line x1={(size >= 768 ? 380 : 380*0.9*0.9)/2} y1="0" x2={(size >= 768 ? 380 : 380*0.9*0.9)/2} y2="160" style={{ stroke: '#BC9B71', strokeWidth: 2 }} />
      </svg>
    </div>
  );

  return (
    <div className="App-page2-wyg">
      <div className="App-page2-wyg-header">
        <h1>Что вы получаете</h1>
        <Pagination minIndex={scrollIndexMin} maxIndex={numSteps+1} currentIndex={scrollIndex} setCurrentIndex={index => {
          const gallery = document.getElementsByClassName("App-page2-wyg-gallery")[0];
          const maxScrollLeftPx = gallery.scrollWidth - gallery.clientWidth;
          if (index > scrollIndex) {
            gallery.scrollLeft += maxScrollLeftPx / numSteps;
          } else {
            gallery.scrollLeft -= maxScrollLeftPx / numSteps;
          }
          setScrollIndex(index);
        }} />
      </div>
      <div className="App-page2-wyg-gallery" onScroll={e => {
        const gallery = e.target as HTMLDivElement;
        const maxScrollLeftPx = gallery.scrollWidth - gallery.clientWidth;
        const step = maxScrollLeftPx / numSteps;
        setScrollIndex(Math.round((gallery.scrollLeft / step) + scrollIndexMin));
      }}>
        {items}
      </div>
    </div>
  );
}
