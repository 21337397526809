import Cookies from 'js-cookie';
import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import logoDark from './logo-dark.svg';
import logoHeaderDesktop from './logo-header-desktop.svg';
import logoLight from './logo-light.svg';

import phoneIcon from './phone-icon.svg';
import phoneIconFooter from './phone-icon-footer.svg';

import decorArrowDown from './decor-arrow-down.svg';
import arrowUp from './decor-arrow-up.svg';

import OurServices from './our-services/widget/src';
import Calculator from './calculator/src';
import OurAdvantages from './our-advantages/src';
import HowToStart from './how-to-start/src';
import WhatYouGet from './what-you-get/src';

import PostanovkaBuhucheta from './our-services/pages/postanovka-buhucheta';
import BuhgalterskoeObsluzhivanie from './our-services/pages/buhgalterskoe-obsluzhivanie';
import VedenieUchastkovBuhgalterii from './our-services/pages/vedenie-uchastkov-buhgalterii';
import RaschetOplatyTruda from './our-services/pages/raschet-oplaty-truda';
import NalogovoeKonsultirovanie from './our-services/pages/nalogovoe-konsultirovanie';
import RazrabotkaUchetnojPolitiki from './our-services/pages/razrabotka-uchetnoj-politiki';
import OrganizaciyaBuhgalterskogoINalogovogoUcheta from './our-services/pages/organizaciya-buhgalterskogo-i-nalogovogo-ucheta';
import VosstanovlenieUcheta from './our-services/pages/vosstanovlenie-ucheta';

import SubmitRequestHeaderForm from './submit-request/forms/vertical/src';
import SubmitRequestFooterForm from './submit-request/forms/horizontal/src';
import SubmitRequestPopup from './submit-request/popup/src';

import SpecialOfferPopup from './special-offer/src';

import Loader from './util/loader/src';
import './util/hamburger/style.css'

import './App.css';


const menuModel: Array<{
  title: string,
  anchor: string
}> = [{
  title: 'Главная',
  anchor: '/#main'
}, {
  title: 'Наши услуги',
  anchor: '/#services'
}, {
  title: 'Цены',
  anchor: '/#calculator'
}, {
  title: 'Преимущества',
  anchor: '/#advantages'
}, {
  title: 'Как начать работу',
  anchor: '/#how-to-start'
}];
enum HeaderMenuType {
  Horizontal,
  Dropdown
}
type HeaderMenuProps = {
  type: HeaderMenuType
}
function HeaderMenu(props: HeaderMenuProps) {
  return (
    props.type === HeaderMenuType.Dropdown
      ? <ul className="hamburger_box">
          <li className="hamburger_title" key={0}>Меню</li>
          {menuModel.map((menu, idx) =>
            <li key={idx+1} onClick={e => {
              const hamburgerButton = document.getElementById('hamburger_toggle');
              if (hamburgerButton) {
                hamburgerButton.click();
              }
            }}>
              <HashLink to={menu.anchor} className="hamburger_item">{menu.title}</HashLink>
            </li>
          )}
        </ul>
      : <div className="App-header-menu-buttons-block">{menuModel.map((menu, idx) =>
          <HashLink key={idx} to={menu.anchor} className="App-header-menu-button">{menu.title}</HashLink>
        )}
        </div>
  );
}

type DesktopHeaderProps = {
  setSubmitRequestPopupIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}
function DesktopHeader(props: DesktopHeaderProps) {
  const [isLargeEnough, setLargeEnough] = useState(window.innerWidth >= 1280);

  const updateMedia = () => {
    setLargeEnough(window.innerWidth >= 1280);
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  });

  const common =
    <div className="App-header-menu-phone-block">
      <img src={phoneIcon} alt='tel.' />
      <div><span>29-77-77;</span><span>+7 (904) 209-77-77</span></div>
      <button className="App-header-menu-button App-header-menu-button-request-call" onClick={e => {
        props.setSubmitRequestPopupIsOpen(true);
      }}>Оставить заявку</button>
    </div>;

  return (
    <div>{isLargeEnough ? (
      <div className="App-header-menu">
        <Link to='/'><img src={logoHeaderDesktop} alt="logo" className="App-header-logo" /></Link>  {/* set scale factor inside svg code */}
        <HeaderMenu type={HeaderMenuType.Horizontal} />
        {common}
      </div>
    ) : (
      <div className="App-header-menu">
        <div className="App-header-menu-hamburger">
          <input id="hamburger_toggle" type="checkbox" />
          <label className="hamburger_btn" htmlFor="hamburger_toggle">
            <span></span>
          </label>
          <HeaderMenu type={HeaderMenuType.Dropdown} />
        </div>
        <Link to='/'><img src={logoHeaderDesktop} alt="logo" className="App-header-logo" /></Link>  {/* set scale factor inside svg code */}
        {common}
      </div>
    )}
    </div>
  );
}


function FooterMenu() {
  const [size, setSize] = useState(window.innerWidth);

  const updateMedia = () => {
    setSize(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  });

  const currentYear = new Date().getFullYear();

  return (
    (size >= 1280) ?
    <div className="App-footer-menu">
      <div>
        <img src={logoLight} alt="logo"/>
        <p>"Профиль" © 2017-{currentYear}</p>
      </div>
      <div>
        <h3>Услуги</h3>
        <ul>
          <li><a href="#services">Бухгалтерское обслуживание</a></li>
          <li><a href="#services">Постановка бухучёта</a></li>
          <li><a href="#services">Ведение участков бухгалтерии</a></li>
          <li><a href="#services">Бух- и налоговый учёт</a></li>
        </ul>
      </div>
      <div>
        <h3>Услуги</h3>
        <ul>
          <li><a href="#services">Расчёт зарплаты</a></li>
          <li><a href="#services">Налоговое консультирование</a></li>
          <li><a href="#services">Разработка учётной политики</a></li>
          <li><a href="#services">Восстановление учёта</a></li>
        </ul>
      </div>
      <div>
        <h3>О компании</h3>
        <ul>
          <li><a href="#main">Главная</a></li>
          <li><a href="#advantages">Преимущества</a></li>
          <li><a href="#how-to-start">Как начать работу</a></li>
        </ul>
      </div>
      <div>
        <div style={{ display: 'flex', flexDirection: 'row', fontWeight: 'bold' }}>
          <img src={phoneIconFooter} style={{ marginRight: '1em' }} alt='tel.' />
          <p>29-77-77</p>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', fontWeight: 'bold' }}>
          <img src={phoneIconFooter} style={{ marginRight: '1em' }} alt='tel.' />
          <p>+7 (904) 209-77-77</p>
        </div>
      </div>
    </div> :

    (size >= 768 && size < 1280) ?
    <div className="App-footer-menu">
      <div>
        <img src={logoLight} alt="logo"/>
        <p>"Профиль" © 2017-{currentYear}</p>
      </div>
      <div>
        <h3>Услуги</h3>
        <ul>
          <li><a href="#services">Бухобслуживание</a></li>
          <li><a href="#services">Бухучёт</a></li>
          <li><a href="#services">Ведение участков</a></li>
          <li><a href="#services">Расчёт зарплаты</a></li>
          <li><a href="#services">Налоги</a></li>
          <li><a href="#services">Учётная политика</a></li>
          <li><a href="#services">Налоги</a></li>
          <li><a href="#services">Восстановление учёта</a></li>
        </ul>
      </div>
      <div>
        <h3>О компании</h3>
        <ul>
          <li><a href="#main">Главная</a></li>
          <li><a href="#advantages">Преимущества</a></li>
          <li><a href="#how-to-start">Как начать</a></li>
        </ul>
      </div>
      <div>
        <div style={{ display: 'flex', flexDirection: 'row', fontWeight: 'bold' }}>
          <img src={phoneIconFooter} style={{ marginRight: '1em' }} alt='tel.' />
          <p>29-77-77</p>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', fontWeight: 'bold' }}>
          <img src={phoneIconFooter} style={{ marginRight: '1em' }} alt='tel.' />
          <p>+7 (904) 209-77-77</p>
        </div>
      </div>
    </div> :

    <div className="App-footer-menu">
      <img src={logoLight} alt="logo"/>
      <div style={{ marginBottom: '2em' }}>
        <h3>О компании</h3>
        <ul>
          <li><a href="#main">Главная</a></li>
          <li><a href="#advantages">Преимущества</a></li>
          <li><a href="#how-to-start">Как начать работу</a></li>
        </ul>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ display: 'flex', flexDirection: 'row', fontWeight: 'bold' }}>
          <img src={phoneIconFooter} style={{ marginRight: '1em' }} alt='tel.' />
          <p style={{ marginRight: '1em' }}>29-77-77;</p>
          <p>+7 (904) 209-77-77</p>
        </div>
      </div>
    </div>
  );
}


function App() {
  const [size, setSize] = useState(window.innerWidth);

  const [submitRequestPopupIsOpen, setSubmitRequestPopupIsOpen] = useState(false);
  const [calcReport, setCalcReport] = useState({});

  const [showSpecialOffer, setShowSpecialOffer] = useState(false);

  const updateMedia = () => setSize(window.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', updateMedia);

    let specialOfferTimer: number | undefined = undefined;
    const notInterestedInSpecialOffer = Cookies.get('notInterestedInSpecialOffer');
    // console.log('notInterestedInSpecialOffer', notInterestedInSpecialOffer);
    if (notInterestedInSpecialOffer) {
      // пользователь был на сайте менее месяца назад и отказался от спец-предложения
    } else {
      // пользователь был на сайте более месяца назад ИЛИ выбрал "Напомнить позже"
      specialOfferTimer = window.setTimeout(() => {
        setShowSpecialOffer(true);
      }, 1000 * 60 * 1);  // 1 minute
      // console.log('"show special offer" timer START');
    }

    return () => {
      window.removeEventListener('resize', updateMedia);
      if (specialOfferTimer !== undefined) {
        clearTimeout(specialOfferTimer);
        // console.log('"show special offer" timer STOP');
      }
    };
  });

  return (
    <Router>{
      (size >= 768) ?
      <div className="App" id="main">
        <DesktopHeader setSubmitRequestPopupIsOpen={setSubmitRequestPopupIsOpen} />

        <Switch>
          <Route path='/buhgalterskoe-obsluzhivanie'>
            <BuhgalterskoeObsluzhivanie setSubmitRequestPopupIsOpen={setSubmitRequestPopupIsOpen} />
          </Route>
          <Route path='/postanovka-buhucheta'>
            <PostanovkaBuhucheta setSubmitRequestPopupIsOpen={setSubmitRequestPopupIsOpen} />
          </Route>
          <Route path='/vedenie-uchastkov-buhgalterii'>
            <VedenieUchastkovBuhgalterii setSubmitRequestPopupIsOpen={setSubmitRequestPopupIsOpen} />
          </Route>
          <Route path='/raschet-oplaty-truda'>
            <RaschetOplatyTruda setSubmitRequestPopupIsOpen={setSubmitRequestPopupIsOpen} />
          </Route>
          <Route path='/nalogovoe-konsultirovanie'>
            <NalogovoeKonsultirovanie setSubmitRequestPopupIsOpen={setSubmitRequestPopupIsOpen} />
          </Route>
          <Route path='/razrabotka-uchetnoj-politiki'>
            <RazrabotkaUchetnojPolitiki setSubmitRequestPopupIsOpen={setSubmitRequestPopupIsOpen} />
          </Route>
          <Route path='/organizaciya-buhgalterskogo-i-nalogovogo-ucheta'>
            <OrganizaciyaBuhgalterskogoINalogovogoUcheta setSubmitRequestPopupIsOpen={setSubmitRequestPopupIsOpen} />
          </Route>
          <Route path='/vosstanovlenie-ucheta'>
            <VosstanovlenieUcheta setSubmitRequestPopupIsOpen={setSubmitRequestPopupIsOpen} />
          </Route>
          <Route path='/'>
            <header className="App-header">
              <div className="App-header-content">
                <div>
                  <h1 className="App-header-text-title">Аутсорсинг бухгалтерских услуг в Сыктывкаре</h1>
                  <p className="App-header-text">Попробуйте 14-дневный тестовый период удаленной бухгалтерии по системе "всё включено" за 0 рублей</p>
                </div>
                <img src={decorArrowDown} alt="logo" className="App-header-decor-arrow-down" />
              </div>
            </header>

            <div className="App-page1">
              <OurServices />
              <Calculator />
              <OurAdvantages />
            </div>

            <div className="App-page2">
              <WhatYouGet />
            </div>

            <div className="App-footer">
              <HowToStart />
              <SubmitRequestFooterForm />
              <div className="App-footer-up" onClick={e => window.scrollTo({ top: 0, behavior: 'smooth' })}>
                <p>Наверх</p>
                <img src={arrowUp} alt="up"/>
              </div>
              <FooterMenu />
            </div>
          </Route>
        </Switch>

        <Loader />
        {submitRequestPopupIsOpen
          ? <SubmitRequestPopup onClose={() => setSubmitRequestPopupIsOpen(false)} />
          : null}
        {showSpecialOffer
          ? <SpecialOfferPopup onClose={() => setShowSpecialOffer(false)} />
          : null}
      </div> :

      <div className="App-mobile" id="main">
        <div className="App-mobile-page1">
          <div className="header">
            <div className="App-header-menu-hamburger" style={{ marginLeft: '20px' }}>
              <input id="hamburger_toggle" type="checkbox" />
              <label className="hamburger_btn" htmlFor="hamburger_toggle">
                <span></span>
              </label>
              <HeaderMenu type={HeaderMenuType.Dropdown} />
            </div>
            <img src={logoDark} alt="logo" className="App-header-logo" style={{ marginLeft: '20px' }} />  {/* set scale factor inside svg code */}
          </div>
          <div className="content">
            <h1>Аутсорсинг бухгалтерских услуг в Сыктывкаре</h1>
            <p>Попробуйте 14-дневный тестовый период удаленной бухгалтерии по системе "всё включено" за 0 рублей</p>
          </div>

          <div className="App-mobile-header-submit-request-container">
            <SubmitRequestHeaderForm calcReport={calcReport} />
          </div>
        </div>

        <div className="App-mobile-page2" style={{
          paddingTop: calcReport && Object.keys(calcReport).length ? '70px' : 0
        }}>
          <OurServices />
        </div>

        <div className="App-mobile-calculator">
          <Calculator setCalcReportMobile={setCalcReport} />
        </div>

        <div className="App-mobile-page3">
          <OurAdvantages />
        </div>

        <div className="App-mobile-page4">
          <WhatYouGet />
        </div>

        <div className="App-mobile-page5">
          <HowToStart />
        </div>

        <div className="App-mobile-page6">
          <SubmitRequestFooterForm />
        </div>

        <div className="App-mobile-footer">
          <FooterMenu />
        </div>

        <Loader />
        {showSpecialOffer
          ? <SpecialOfferPopup onClose={() => setShowSpecialOffer(false)} />
          : null}
      </div>
    }</Router>
  );
}

export default App;
