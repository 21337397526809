import React, { useEffect, useState } from 'react';

import piggyBox from './img/piggy-box.svg';
import graph from './img/graph.svg';
import coinStack from './img/coin-stack.svg';
import bank from './img/bank.svg';
import briefcase from './img/briefcase.svg';
import calculator from './img/calculator.svg';

import background from './img/bg.svg';

import './style.css';


export default function OurAdvantages() {
  const [size, setSize] = useState(window.innerWidth);

  const updateMedia = () => setSize(window.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  });

  const common =
    <div className="App-page1-advantages-block">
      <h1>Наши преимущества</h1>
      <div className="App-page1-advantages-block-grid">
        <div>
          <img src={piggyBox} alt="piggy box"/>
          <div>
            <h3>Выгодно.</h3>
            <p>Стараемся предложить лучшие варианты для снижения налогового бремени</p>
          </div>
        </div>
        <div>
          <img src={graph} alt="graph"/>
          <div>
            <h3>Перспективно.</h3>
            <p>Смотрим вперед и помогаем планировать налоговую нагрузку, а также прогнозируем кассовые резервы</p>
          </div>
        </div>
        <div>
          <img src={coinStack} alt="coin stack"/>
          <div>
            <h3>По хорошей цене.</h3>
            <p>Снижаем расходы на ведение бухгалтерии в несколько раз</p>
          </div>
        </div>
        <div>
          <img src={bank} alt="bank building"/>
          <div>
            <h3>Без рутины.</h3>
            <p>Берём на себя все общение с банками, налоговой и всю вашу текучку</p>
          </div>
        </div>
        <div>
          <img src={briefcase} alt="briefcase"/>
          <div>
            <h3>Без проблем.</h3>
            <p>Проверяем ваших контрагентов</p>
          </div>
        </div>
        <div>
          <img src={calculator} alt="calculator"/>
          <div>
            <h3>Без рисков.</h3>
            <p>Прогнозируем риски и подсказываем решения</p>
          </div>
        </div>
      </div>
    </div>;

  return (
    (size >= 1600) ?
    <div className="App-page1-advantages" id="advantages">
      <img src={background} alt="" />
      {common}
    </div> :

    (size >= 1280 && size < 1600) ?
    <div className="App-page1-advantages" id="advantages">
      <img src={background} alt="" style={{
        transform: `scale(0.78, 1.0)`,
        position: 'relative',
        left: '-172px'
      }} />
      {common}
    </div> :

    (size >= 768 && size < 1280) ?
    <div className="App-page1-advantages" id="advantages">
      <img src={background} alt="" style={{
        transform: `scale(0.5, 1.25)`,
        position: 'relative',
        top: '85px',
        left: '-413px'
      }} />
      {common}
    </div> :

    <div className="App-mobile-advantages" id="advantages">
      {common}
    </div>
  );
}
