import React from 'react';

import Spinner from '../spinner/src';

import './style.css';


export default function Loader() {
  return (
    <div id="loader" className="modal">
      <div className="modal-content modal-content-spinner">
        <Spinner />
      </div>
    </div>
  );
}
