import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import headerImg from './img/nalogovoe-konsultirovanie.jpeg';
import checkImg from './check.svg';

import { PageProps, SubmitRequest, useMeta } from './common';

import './style.css';


export default function NalogovoeKonsultirovanie(props: PageProps) {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useMeta('Налоговые консультации онлайн – разово и на постоянной основе', {
    description: 'Опытный налоговый консультант – стаж от 15 лет. Проводим налоговые консультации онлайн для физических и юридических лиц, ИП в Сыктывкаре и Республике Коми. Налоговое консультирование по любым вопросам. Цены на консультации по налогам от 500 руб.',
    keywords: 'налоговая консультация, налоговый консультант, налоговое консультирование, консультации по налогам, налоговая консультация онлайн'
  });

  return (
    <div className="our-service-page">
      <HashLink to="/#services" className="goback">{`< Вернуться`}</HashLink>
      <div className="header">
        <div>
          <h1>Налоговая консультация в режиме онлайн</h1>
          <span>Быстрое решение любых вопросов</span>
          <p>Консультация по налогам поможет защитить бизнес от претензий со стороны налоговых органов, а также уменьшить нагрузку на предприятие в законном порядке без риска получить штраф. Благодаря опыту найдем решение для любой ситуации – в штате компании бухгалтеры и юристы со стажем от 15 лет, при необходимости обратимся к экспертам в смежных областях.</p>
        </div>
        <img src={headerImg} alt="stock" />
      </div>
      <div className="highlights">
        <div>
          <img src={checkImg} alt="check"/>
          <p>Помощь по разным направлениям – от консультирования по налогам до проверки договоров</p>
        </div>
        <div>
          <img src={checkImg} alt="check"/>
          <p>Сотрудничество с физическими и юридическими лицами, ИП из Республики Коми</p>
        </div>
        <div>
          <img src={checkImg} alt="check"/>
          <p>Учет действующего российского законодательства, соблюдение требований МИФНС, ПФР, ФСС</p>
        </div>
        <div>
          <img src={checkImg} alt="check"/>
          <p>Гарантия конфиденциальности – работа строго по правилам корпоративной этики</p>
        </div>
      </div>
      <SubmitRequest
        text="Согласуйте удобное время для налоговой консультации – работаем каждый день"
        style={{
          marginTop: '2em',
          marginBottom: '6em'
        }}
        onClick={() => props.setSubmitRequestPopupIsOpen(true)}
      />
      <h2 className="title">Стоимость налогового консультирования от 500 рублей</h2>
      <p className="subtitle">Цена напрямую зависит от сложности вопроса</p>
      <h2 className="title">Полезная информация по бухгалтерскому аутсорсингу</h2>
      <p className="subtitle">Ответы на самые частые вопросы</p>
      <p className="question">Как обычно проходит налоговая консультация онлайн?</p>
      <p className="text">Выбираем удобный способ связи – например, Skype или Zoom, созваниваемся в согласованное время. Во время сессии налоговый консультант ответит на ваши вопросы.</p>
      <p className="question">Возможна ли встреча в офисе?</p>
      <p className="text">Да, можем встретиться в вашем или нашем офисе в Сыктывкаре.</p>
      <SubmitRequest
        text="Воспользуйтесь помощью налогового консультанта – уменьшите нагрузку на свое предприятие"
        style={{
          marginTop: '5em',
          marginBottom: '5em'
        }}
        onClick={() => props.setSubmitRequestPopupIsOpen(true)}
      />
    </div>
  );
}
