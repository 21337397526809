import React, { useEffect, useState } from 'react';

import Pagination from '../util/pagination/src';
import SubmitRequestPopup from '../submit-request/popup/src';

import './style.css';


type ТипОрганизации =
  'ООО' |
  'ИП';


type ТипНалогообложения =
  'ОСНО' |
  'Доходы' |
  'ДоходыМинусРасходы';


function calcMonthPrice(
  orgType: ТипОрганизации,
  taxType: ТипНалогообложения,
  numberOfStaff: number,
  обработкаБанка: number,
  реализацияТоваровИУслуг: number,
  поступлениеТоваровИУслуг: number,
  ОСиНМА: number,
  кассовыйДокументооборот: number,
  авансовыеОтчеты: number
): number
{
  const документооборот =
    обработкаБанка * 78.5 +
    реализацияТоваровИУслуг * 78.5 +
    поступлениеТоваровИУслуг * 78.5 +
    ОСиНМА * 47.1 +
    кассовыйДокументооборот * 78.5 +
    авансовыеОтчеты * 78.5;

  let декларации = 588.75;
  if (numberOfStaff === 0) {
    if (taxType === 'ОСНО') {
      декларации = 431.75;
    } else if (orgType === 'ООО') {
      декларации = 274.75;
    } else if (orgType === 'ИП') {
      декларации = 196.25;
    }
  }

  let документыПоЗП = 0;
  if (numberOfStaff === 0) {
    if (orgType === 'ООО') {
      документыПоЗП = 637.16;
    } else if (orgType === 'ИП') {
      документыПоЗП = 0;
    }
  } else {
    документыПоЗП = 637.16 + numberOfStaff * 580.9;
  }

  return документооборот + декларации + документыПоЗП;
}


function setValueFromInput(
  e: React.FormEvent,
  setter: (value: React.SetStateAction<any>) => void
): void
{
  const input = e.target as HTMLInputElement;
  const valueAsInt = parseInt(input.value);
  if (isNaN(valueAsInt)) {
    setter(input.value);
  } else {
    setter(valueAsInt);
  }
}


type CalculatorProps = {
  setCalcReportMobile?: React.Dispatch<React.SetStateAction<object>>
}

export default function Calculator(props: CalculatorProps) {
  const [size, setSize] = useState(window.innerWidth);

  const [submitRequestPopupIsOpen, setSubmitRequestPopupIsOpen] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);

  const [orgType, setOrgType] = useState<ТипОрганизации>('ООО');
  const [taxType, setTaxType] = useState<ТипНалогообложения>('ОСНО');
  const [numberOfStaff, setNumberOfStaff] = useState(0);
  const [обработкаБанка, setОбработкаБанка] = useState(1);
  const [реализацияТоваровИУслуг, setРеализацияТоваровИУслуг] = useState(1);
  const [поступлениеТоваровИУслуг, setПоступлениеТоваровИУслуг] = useState(1);
  const [ОСиНМА, setОСиНМА] = useState(1);
  const [кассовыйДокументооборот, setКассовыйДокументооборот] = useState(1);
  const [авансовыеОтчеты, setАвансовыеОтчеты] = useState(1);

  const updateMedia = () => setSize(window.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  });


  const servicesQuantities = [{
    title: 'Обработка банка',
    name: 'обработка-банка',
    value: обработкаБанка,
    setter: setОбработкаБанка
  }, {
    title: 'Реализация товаров и услуг',
    name: 'реализация-товаров-и-услуг',
    value: реализацияТоваровИУслуг,
    setter: setРеализацияТоваровИУслуг
  }, {
    title: 'Поступление товаров и услуг',
    name: 'поступление-товаров-и-услуг',
    value: поступлениеТоваровИУслуг,
    setter: setПоступлениеТоваровИУслуг
  }, {
    title: 'ОС и НМА',
    name: 'ОС-и-НМА',
    value: ОСиНМА,
    setter: setОСиНМА
  }, {
    title: 'Кассовый документооборот',
    name: 'кассовый-документооборот',
    value: кассовыйДокументооборот,
    setter: setКассовыйДокументооборот
  }, {
    title: 'Авансовые отчеты',
    name: 'авансовые-отчеты',
    value: авансовыеОтчеты,
    setter: setАвансовыеОтчеты
  }];

  const resultSum = calcMonthPrice(
    orgType,
    taxType,
    numberOfStaff,
    обработкаБанка,
    реализацияТоваровИУслуг,
    поступлениеТоваровИУслуг,
    ОСиНМА,
    кассовыйДокументооборот,
    авансовыеОтчеты
  ).toLocaleString('ru-RU', { maximumFractionDigits: 2 }) + ' руб.';

  const servicesReport = servicesQuantities.reduce((obj, service) =>
    Object.assign(obj, { [service.title]: service.value })
  , {});
  const calcReport = Object.assign(servicesReport, {
    'Организационная форма': orgType,
    'Тип налогообложения': taxType,
    'Количество сотрудников': numberOfStaff,
    'Итого': resultSum
  });


  const CalcResult =
    <div className="calc-result">
      <p className="calc-result-sum">{resultSum
        ? `от ${resultSum}`
        : `0 руб.`
      }</p>
    </div>;


  const PageOne =
    <form className="form">
      <div className="question">
        <span>Организационная форма:</span>
        <div className="radios-box" onChange={e => setValueFromInput(e, setOrgType)}>
          <div>
            <input type="radio" id="calc-org-type-1" name="calc-org-type" value={'ООО'} defaultChecked={orgType === 'ООО'} />
            <label htmlFor="calc-org-type-1">ООО</label>
          </div>
          <div>
            <input type="radio" id="calc-org-type-2" name="calc-org-type" value={'ИП'} defaultChecked={orgType === 'ИП'} />
            <label htmlFor="calc-org-type-2">ИП</label>
          </div>
        </div>
      </div>
      <div className="question">
        <span>Тип налогообложения:</span>
        <div className="radios-box" onChange={e => setValueFromInput(e, setTaxType)}>
          <div>
            <input type="radio" id="calc-tax-type-1" name="calc-tax-type" value={'ОСНО'} defaultChecked={taxType === 'ОСНО'} />
            <label htmlFor="calc-tax-type-1">ОСНО</label>
          </div>
          <div>
            <input type="radio" id="calc-tax-type-2" name="calc-tax-type" value={'Доходы'} defaultChecked={taxType === 'Доходы'} />
            <label htmlFor="calc-tax-type-2">Доходы</label>
          </div>
          <div>
            <input type="radio" id="calc-tax-type-3" name="calc-tax-type" value={'ДоходыМинусРасходы'} defaultChecked={taxType === 'ДоходыМинусРасходы'} />
            <label htmlFor="calc-tax-type-3">Доходы-Расходы</label>
          </div>
        </div>
      </div>
      <div className="question">
        <span>Количество сотрудников: {numberOfStaff}</span>
        <div className="slider-box">
          <input type="range" id="number-of-staff" name="number-of-staff" max="50" defaultValue={numberOfStaff} onInput={e => setValueFromInput(e, setNumberOfStaff)} />
        </div>
      </div>
    </form>;


  const PageTwo =
    <div>
      <h2 className="sub-title">Ваш документооборот, шт.</h2>
      <form className="form">
        {servicesQuantities.map(service =>
          <div className="question" key={service.name}>
            <span>{service.title}: {service.value}</span>
            <div className="slider-box">
              <input type="range" id={service.name} name={service.name} max="50" defaultValue={service.value} onInput={e => setValueFromInput(e, service.setter)} />
            </div>
          </div>
        )}
      </form>
      <button className="submit-button" onClick={event => {
        event.preventDefault();
        if (size >= 768) {
          setSubmitRequestPopupIsOpen(true);
        } else if (props.setCalcReportMobile) {
          props.setCalcReportMobile(calcReport);
          const form = document.getElementsByClassName('App-mobile-header-submit-request-container')[0];
          form.scrollIntoView({ behavior: 'smooth' });
        }
        setPageNumber(1);
      }}>Оставить заявку</button>
    </div>;


  return (
    <div className="Calculator-container" id="calculator">
      <div className="Calculator">
        <div className="header">
          <h1>Расчёт стоимости услуг</h1>
          <Pagination minIndex={1} maxIndex={2} currentIndex={pageNumber} setCurrentIndex={setPageNumber} />
        </div>
        {
          pageNumber === 1 ? PageOne :
          pageNumber === 2 ? PageTwo : null
        }
        {CalcResult}
        {submitRequestPopupIsOpen
          ? <SubmitRequestPopup
              calcReport={calcReport}
              onClose={() => setSubmitRequestPopupIsOpen(false)} />
          : null}
      </div>
    </div>
  );
}
