import { useEffect } from 'react';


type SubmitRequestProps = {
  onClick: () => void;
  text: string;
  style?: any;
}
function SubmitRequest(props: SubmitRequestProps) {
  return (
    <div className="submit-request" style={props.style} >
      <span>{props.text}</span>
      <button onClick={props.onClick}>Оставить заявку</button>
    </div>
  );
}

type Meta = {
  description?: string,
  keywords?: string;
}
function useMeta(title: string, meta: Meta = {}) {
  useEffect(() => {
    const oldDocumentTitle = document.title;
    document.title = title;

    const oldMeta: [HTMLMetaElement, string][] = [];
    Object.entries(meta).forEach(([key, value]) => {
      if (value) {
        let el = document.querySelector(`meta[name="${key}"]`) as (HTMLMetaElement | null);
        if (!el) {
          el = document.createElement('meta');
          el.setAttribute('name', key);
          document.getElementsByTagName('head')[0].appendChild(el);
        }
        oldMeta.push([el, el.getAttribute('content') ?? '']);
        el.setAttribute('content', value);
      }
    });

    return () => {
      document.title = oldDocumentTitle;
      oldMeta.forEach(([el, content]) => el.setAttribute('content', content));
    };
  });
}


export type PageProps = {
  setSubmitRequestPopupIsOpen: React.Dispatch<React.SetStateAction<boolean>>
};


export {
  SubmitRequest,
  useMeta
};
