import React, { useEffect, useState } from 'react';

import arrowRight from '../img/arrow-right.svg';
import circle from '../util/circle';

import step1 from './img/step-1.svg';
import step2 from './img/step-2.svg';
import step3 from './img/step-3.svg';

import './style.css';


function ArrowRight() {
  const [size, setSize] = useState(window.innerWidth);

  const updateMedia = () => setSize(window.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  });

  return ((size >= 768)
    ? <div className="App-arrow App-footer-hts-steps-step-arrow">
        <img src={arrowRight} alt="" className="App-arrow-right" />
        <svg width="32" height="32" className="App-arrow-right-circle">
          {circle}
        </svg>
      </div>
    : null
  );
}


export default function HowToStart() {
  return (
    <div className="App-footer-hts" id="how-to-start">
      <div className="App-footer-hts-content">
        <h1>Как начать работу</h1>
        <div className="App-footer-hts-steps">
          <div className="App-footer-hts-steps-step">
            <img src={step1} alt="" />
            <h3>Шаг 1</h3>
            <p>Оставьте заявку на сайте или позвоните по телефону</p>
          </div>
          <ArrowRight />
          <div className="App-footer-hts-steps-step">
            <img src={step2} alt="" />
            <h3>Шаг 2</h3>
            <p>Проводим анализ предприятия и задач. Предлагаем варианты решения</p>
          </div>
          <ArrowRight />
          <div className="App-footer-hts-steps-step">
            <img src={step3} alt="" />
            <h3>Шаг 3</h3>
            <p>Мы подписываем договор и соглашение о конфиденциальности</p>
          </div>
        </div>
      </div>
    </div>
  );
}
