import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import headerImg from './img/organizaciya-buhgalterskogo-i-nalogovogo-ucheta.jpeg';
import checkImg from './check.svg';

import { PageProps, SubmitRequest, useMeta } from './common';

import './style.css';


export default function OrganizaciyaBuhgalterskogoINalogovogoUcheta(props: PageProps) {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useMeta('Организация бухгалтерского учета в срок от 2 дней в Республике Коми', {
    description: 'Услуги организации бухгалтерского учета в Сыктывкаре и Республике Коми. Возможна организация бухгалтерского и налогового учета для юридических лиц любых форм собственности. Цены на организацию учета от 1 000 руб., расчет в течение 30 минут.',
    keywords: 'организация бухгалтерского учета, организация налогового учета, услуги организации бухгалтерского учета, организация бухгалтерского и налогового учета'
  });

  return (
    <div className="our-service-page">
      <HashLink to="/#services" className="goback">{`< Вернуться`}</HashLink>
      <div className="header">
        <div>
          <h1>Организация бухгалтерского и налогового учета</h1>
          <span>Комплексно и по отдельным направлениям деятельности</span>
          <p>Успех бизнеса зависит от организации бухгалтерского учета – в случае правильного выбора системы налогообложения, формирование графика документооборота вы избежите лишних расходов и штрафов.</p>
        </div>
        <img src={headerImg} alt="stock" style={{ height: '320px' }} />
      </div>
      <p className="paragraph" style={{ marginTop: 0 }}>Рекомендуем проводить организацию бухгалтерского и налогового учета при открытии и реорганизации компании, изменении сферы деятельности и законодательной базы. Поможем выстроить систему под ключ или выполним работы по отдельным направлениям – состав услуг обсуждается индивидуально.</p>
      <div className="highlights">
        <div>
          <img src={checkImg} alt="check"/>
          <p>Эксперты в сфере налогов, ведения бухучета и права с опытом от 15 лет</p>
        </div>
        <div>
          <img src={checkImg} alt="check"/>
          <p>Сотрудничество с юридическими и физическими лицами, ИП</p>
        </div>
        <div>
          <img src={checkImg} alt="check"/>
          <p>Работа по договору и финансовая ответственность за результат</p>
        </div>
        <div>
          <img src={checkImg} alt="check"/>
          <p>Гарантия конфиденциальности, соблюдаем корпоративную этику</p>
        </div>
      </div>
      <SubmitRequest
        text="Закажите постановку бухгалтерского учета – приступим к работе в течение 24 часов"
        style={{
          marginTop: '2em',
          marginBottom: '6em'
        }}
        onClick={() => props.setSubmitRequestPopupIsOpen(true)}
      />
      <h2 className="title">Стоимость услуг организации бухгалтерского учета</h2>
      <p className="subtitle">Точная цена будет известна заранее</p>
      <h2 className="title">Частые вопросы по организации налогового и бухгалтерского учета</h2>
      <p className="subtitle">Полезная информация по услуге</p>
      <p className="question">Как происходит постановка бухучета?</p>
      <p className="text">Сначала обсуждаем сотрудничество, после согласования приступаем к работе: изучаем особенности бизнеса и на основе полученной информации формируем первичную документацию, разносим банковские операции по разным счетам и т. д.</p>
      <p className="question">Вы ставите бухгалтерский учет с нуля?</p>
      <p className="text">Да, помогаем организовать систему при открытии компании.</p>
      <SubmitRequest
        text="Узнайте точную стоимость и сроки постановки учета – оставьте заявку, перезвоним вам"
        style={{
          marginTop: '5em',
          marginBottom: '5em'
        }}
        onClick={() => props.setSubmitRequestPopupIsOpen(true)}
      />
    </div>
  );
}
