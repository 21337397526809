export const telMask = '+7 (999) 999-99-99';


type SubmitRequestFormData = {
  name: string,
  tel: string,
  org: string,
  descr?: string,
  calcReport?: object
}

export function submitRequestForm(form: SubmitRequestFormData) {
  const loader = document.getElementById('loader');
  if (loader) {
    loader.style.display = "block";
  }

  grecaptcha.ready(() => {
    grecaptcha.execute('6LeNJbkZAAAAAA2Vbcorz9H-i8M3x_6ctuiDJ9HN', { action: 'submit' }).then(async grecaptchaToken => {
      const data = new FormData();
      data.append('grecaptchaToken', grecaptchaToken);
      data.append('name', form.name);
      data.append('tel', form.tel);  // .replace(/(\+7| |\(|\)|-)/g, '') - удаляем все символы кроме цифр
      data.append('org', form.org);
      if (form.descr) {
        data.append('descr', form.descr);
      }
      if (form.calcReport && Object.keys(form.calcReport).length) {
        data.append('calcReport', JSON.stringify(form.calcReport));
      }

      let msg = '';
      try {
        // await new Promise(resolve => setTimeout(resolve, 5000));
        const res = await fetch('/submit.wsgi', {
          method: 'POST',
          body: data
        });
        await res.json();  // const issue =
        msg = 'Мы получили ваше сообщение, спасибо!';
      } catch (error) {
        console.error(error);
        msg = 'К сожалению, что-то пошло не так :( Но вы всегда можете позвонить нам по телефонам, указанным в шапке и подвале страницы!';
      }

      if (loader) {
        loader.style.display = "none";
      }
      setTimeout(() => alert(msg), 0);
    });
  });
}
