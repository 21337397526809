import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import headerImg from './img/raschet-oplaty-truda.jpeg';
import checkImg from './check.svg';

import { PageProps, SubmitRequest, useMeta } from './common';

import './style.css';


export default function RaschetOplatyTruda(props: PageProps) {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useMeta('Расчет заработной платы персонала онлайн – закажите услугу', {
    description: 'Точный расчет оплаты труда персонала в Сыктывкаре и других городах Республики Коми. Услуга расчета заработной платы стоит от 3 000 рублей в месяц. Возможен расчет заработной платы онлайн – используем электронный документооборот.',
    keywords: 'расчет заработной платы, расчет оплаты труда персонала, услуга расчета заработной платы, расчет заработной платы онлайн'
  });

  return (
    <div className="our-service-page">
      <HashLink to="/#services" className="goback">{`< Вернуться`}</HashLink>
      <div className="header">
        <div>
          <h1>Расчет заработной платы персонала</h1>
          <span>Защита от трудовых споров и налоговых рисков</span>
          <p>Передайте расчет оплаты труда персонала на аутсорсинг – без ошибок определим сумму для каждого сотрудника, вовремя оплатим необходимые налоги и взносы, отчитаемся перед государственными органами. Все в строгом соответствии с актуальным законодательством – отслеживаем изменения и нововведения.</p>
        </div>
        <img src={headerImg} alt="stock" />
      </div>
      <div className="highlights">
        <div>
          <img src={checkImg} alt="check"/>
          <p>Сотрудничество с коммерческими и некоммерческими организациями, предпринимателями</p>
        </div>
        <div>
          <img src={checkImg} alt="check"/>
          <p>Расчет зарплаты для любого количество персонала – не важно, сколько людей у вас в штате</p>
        </div>
        <div>
          <img src={checkImg} alt="check"/>
          <p>Консультации по возникающим вопросам – обращайтесь, если что-то покажется непонятным</p>
        </div>
        <div>
          <img src={checkImg} alt="check"/>
          <p>Работа в облачных сервисах, общение по телефону и email – нет необходимости посещать офис</p>
        </div>
      </div>
      <SubmitRequest
        text="Обсудите услугу расчета заработной платы – расскажем вам обо всех нюансах"
        style={{
          marginTop: '2em',
          marginBottom: '6em'
        }}
        onClick={() => props.setSubmitRequestPopupIsOpen(true)}
      />
      <h2 className="title">Цена расчета оплаты труда персонала от 3 000 руб./месяц</h2>
      <p className="subtitle">Согласованная сумма фиксируется в договоре</p>
      <h2 className="title">Частые вопросы по услуге расчета заработной платы</h2>
      <p className="subtitle">Полезная информация</p>
      <p className="question">Как происходит онлайн расчет заработной платы?</p>
      <p className="text">Все индивидуально – мы согласовываем детали и поэтапно выстраиваем процесс взаимодействия с каждым заказчиком.</p>
      <p className="question">Что именно входит в стоимость услуги?</p>
      <p className="text">Расчет оплаты труда персонала дважды в месяц, а также подготовка справок о доходах, квартальных и годовых отчетов и другие работы – подробно расскажем вам о составе услуги во время бесплатной предварительной консультации.</p>
      <SubmitRequest
        text="Узнайте точную стоимость расчета заработной платы персонала – оставьте заявку, перезвоним вам"
        style={{
          marginTop: '5em',
          marginBottom: '5em'
        }}
        onClick={() => props.setSubmitRequestPopupIsOpen(true)}
      />
    </div>
  );
}
