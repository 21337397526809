import arrowLeft from '../../img/arrow-left.svg';
import arrowRight from '../../img/arrow-right.svg';
import circle from '../../util/circle';

import './style.css';


type PaginationProps = {
  minIndex: number;
  maxIndex: number;
  currentIndex: number;
  setCurrentIndex: (value: React.SetStateAction<number>) => void;
}

export default function Pagination(props: PaginationProps = {
  minIndex: 1,
  maxIndex: 10,
  currentIndex: 1,
  setCurrentIndex: (index) => console.log('Pagination index:', index)
}) {
  return (
    <div className="pagination">
      <div className="App-arrow pagination-arrow" style={{marginRight: '1em'}} onClick={e => {
        if (props.currentIndex > props.minIndex) {
          props.setCurrentIndex(props.currentIndex - 1);
        }
      }}>
        <img src={arrowLeft} alt="" className="pagination-arrow-left" />
        <svg width="32" height="32" className="pagination-circle-left">
          {circle}
        </svg>
      </div>
      <h1>{props.currentIndex + '/' + props.maxIndex}</h1>
      <div className="App-arrow pagination-arrow" style={{marginLeft: '1em'}} onClick={e => {
        if (props.currentIndex < props.maxIndex) {
          props.setCurrentIndex(props.currentIndex + 1);
        }
      }}>
        <img src={arrowRight} alt="" className="App-arrow-right" />
        <svg width="32" height="32" className="App-arrow-right-circle pagination-circle-right">
          {circle}
        </svg>
      </div>
    </div>
  );
}
