import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import headerImg from './img/buhgalterskoe-obsluzhivanie.jpeg';
import checkImg from './check.svg';

import { PageProps, SubmitRequest, useMeta } from './common';

import './style.css';


export default function BuhgalterskoeObsluzhivanie(props: PageProps) {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useMeta('Бухгалтерское обслуживание в Сыктывкаре и Республике Коми', {
    description: 'Услуги бухгалтерского обслуживания – ведение учета, подготовка отчетов, другие работы. Бухгалтерское сопровождение юридических и физических лиц. Расчет цены на бухгалтерский аутсорсинг за 1 час. Центр бухгалтерского обслуживания работает с 2017 г.',
    keywords: 'бухгалтерское обслуживание, услуги бухгалтерского обслуживания, бухгалтерский аутсорсинг, центр бухгалтерского обслуживания, бухгалтерское сопровождение'
  });

  return (
    <div className="our-service-page">
      <HashLink to="/#services" className="goback">{`< Вернуться`}</HashLink>
      <div className="header">
        <div>
          <h1>Комплексное бухгалтерское обслуживание 1C</h1>
          <span>Бесплатный тестовый период на 14 дней</span>
          <p>Попробуйте бухгалтерский аутсорсинг за 0 рублей – если понравится, согласуем стоимость дальнейшего сопровождения и заключим договор. Работаем с индивидуальными предпринимателями, физическими и юридическими лицами любых форм собственности.</p>
        </div>
        <img src={headerImg} alt="stock" />
      </div>
      <div className="highlights">
        <div>
          <img src={checkImg} alt="check"/>
          <p>Гибкие тарифы и полный спектр услуг бухгалтерского обслуживания – заказывайте только нужные вам</p>
        </div>
        <div>
          <img src={checkImg} alt="check"/>
          <p>Удобное взаимодействие в режиме онлайн – экономьте время, нет необходимости посещать наш офис</p>
        </div>
        <div>
          <img src={checkImg} alt="check"/>
          <p>Никакой рутины – делегируйте нашим специалистам общение с банками и налоговой</p>
        </div>
        <div>
          <img src={checkImg} alt="check"/>
          <p>Финансовая ответственность по условиям договора – получите компенсацию, если мы ошибемся</p>
        </div>
      </div>
      <SubmitRequest
        text="Обсудите условия бухгалтерского аутсорсинга – гарантируем конфиденциальность"
        style={{
          marginTop: '2em',
          marginBottom: '6em'
        }}
        onClick={() => props.setSubmitRequestPopupIsOpen(true)}
      />
      <h2 className="title">Актуальные цены на услуги бухгалтерского обслуживания</h2>
      <p className="subtitle">Снимите с себя ответственность за оформление документов и сдачу отчетности</p>
      <p className="text">Оставьте заявку – перезвоним, обсудим детали и рассчитаем стоимость бухгалтерского сопровождения вашей деятельности. Цена напрямую зависит от требующихся услуг, размера компании и типа налогообложения – подробно расскажем обо всех нюансах.</p>
      <h2 className="title">Полезная информация по бухгалтерскому аутсорсингу</h2>
      <p className="subtitle">Ответы на самые частые вопросы</p>
      <p className="question">Есть ли в вашем центре бухгалтерского обслуживания бесплатные консультации?</p>
      <p className="text">Да, перед началом работ ответим на все возникшие у вас вопросы по услуге и условиям сотрудничества.</p>
      <p className="question">Каким образом происходит сопровождение деятельности компании?</p>
      <p className="text">Универсальной формулы нет – мы понимаем, что у каждого бизнеса есть свои особенности, поэтому все обсуждается в индивидуальном порядке. Но в большинстве случаев работа происходит в режиме онлайн: клиенты отправляют нам скан-копии документов, а оригиналы хранят у себя.</p>
      <SubmitRequest
        text="Каждый седьмой месяц бухгалтерского сопровождения – за 0 рублей"
        style={{
          marginTop: '5em',
          marginBottom: '5em'
        }}
        onClick={() => props.setSubmitRequestPopupIsOpen(true)}
      />
    </div>
  );
}
