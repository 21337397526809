import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import headerImg from './img/razrabotka-uchetnoj-politiki.jpeg';
import checkImg from './check.svg';
import nextImg from './next.svg';

import { PageProps, SubmitRequest, useMeta } from './common';

import './style.css';


export default function RazrabotkaUchetnojPolitiki(props: PageProps) {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useMeta('Учетная политика организации под ключ – закажите разработку', {
    description: 'Профессиональная разработка учетной политики организации в Сыктывкаре и других городах Республики Коми. Учетная политика организации стоит от 5 000 руб., срок формирования – до 3 рабочих дней в зависимости от сложности задачи.',
    keywords: 'учетная политика организации, разработка учетной политики организации'
  });

  return (
    <div className="our-service-page">
      <HashLink to="/#services" className="goback">{`< Вернуться`}</HashLink>
      <div className="header">
        <div>
          <h1>Разработка учетной политики организации</h1>
          <span>Индивидуальный пакет решений для вашего бизнеса</span>
          <p style={{ marginTop: '2em' }}>Формирование учетной политики организации является обязательным, если вы применяете ЕНВД, УСН или ОСНО. В остальных ситуациях подготовка данной документации проводится в добровольном порядке – для того, чтобы деятельность бухгалтера была точно регламентирована и организована.</p>
        </div>
        <img src={headerImg} alt="stock" />
      </div>
      <div className="highlights">
        <div>
          <img src={checkImg} alt="check"/>
          <p>Подготовка документов для первичного утверждения и изменение имеющейся УП</p>
        </div>
        <div>
          <img src={checkImg} alt="check"/>
          <p>Подбор законных методов минимизации налоговой нагрузки на ваше предприятие</p>
        </div>
        <div>
          <img src={checkImg} alt="check"/>
          <p>Решение задач любой сложности, в штате компании специалисты со стажем от 15 лет</p>
        </div>
        <div>
          <img src={checkImg} alt="check"/>
          <p>Ответственность за предоставленный результат, заключение договора на услуги</p>
        </div>
      </div>
      <SubmitRequest
        text="Закажите учетную политику компании – приступим к ее разработке в течение 24 часов"
        style={{
          marginTop: '2em',
          marginBottom: '6em'
        }}
        onClick={() => props.setSubmitRequestPopupIsOpen(true)}
      />
      <h2 className="title">Цена формирования учетной политики от 5 000 рублей</h2>
      <p className="subtitle">Точная стоимость известна заранее и не меняется в процессе работы</p>
      <h2 className="title">Срок разработки УП составляют до 3 рабочих дней</h2>
      <p className="subtitle">Как происходит подготовка документации:</p>
      <div className="highlights">
        <div>
          <img src={nextImg} alt="next"/>
          <p>Консультация по услуге и условиям сотрудничества с нами</p>
        </div>
        <div>
          <img src={nextImg} alt="next"/>
          <p>Оценка учетной и финансовой информации о предприятии</p>
        </div>
        <div>
          <img src={nextImg} alt="next"/>
          <p>Разработка стандартов ведения учета организации</p>
        </div>
        <div>
          <img src={nextImg} alt="next"/>
          <p>Передача результатов деятельности заказчику, окончательный расчет</p>
        </div>
      </div>
      <SubmitRequest
        text="Узнайте стоимости и сроки подготовки УП – оставьте заявку, перезвоним вам для обсуждения деталей" style={{
          marginTop: '5em',
          marginBottom: '5em'
        }}
        onClick={() => props.setSubmitRequestPopupIsOpen(true)}
      />
    </div>
  );
}
