import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';

import './style.css';


type SpecialOfferPopupProps = {
  onClose?: () => void;
}

export default function SpecialOfferPopup(props: SpecialOfferPopupProps) {
  const [size, setSize] = useState(window.innerWidth);

  const updateMedia = () => setSize(window.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  });

  return (
    <div id="special-offer-popup" className="modal" style={{ display: 'block' }}>
      <div className="modal-content">
        <div className="special-offer" style={size >= 768 ? {} : {
          width: '85%',
          marginLeft: 'auto',
          marginRight: 'auto'
        }}>
          {size >= 768
            ? <h1>Супер предложение!</h1>
            : <h1>Супер<br/>предложение!</h1>}
          <ul>
            <li>Откроем ИП всего за 300 ₽</li>
            <li>Поможем оформить ООО за 500 ₽ с расчётным счётом</li>
            <li>Заполним за вас 3-НДФЛ за 200 ₽</li>
          </ul>
          <div className="footer">
            <button onClick={e => {
              const endOfDay = new Date();
              endOfDay.setHours(23, 59, 59, 999);
              Cookies.set('notInterestedInSpecialOffer', 'true', { expires: endOfDay });
              if (typeof props.onClose === 'function') {
                props.onClose();
              }
            }}>Напомнить позже</button>
            <button onClick={e => {
              Cookies.set('notInterestedInSpecialOffer', 'true', { expires: 30 });  // 30 days
              if (typeof props.onClose === 'function') {
                props.onClose();
              }
            }}>Не интересно</button>
          </div>
        </div>
      </div>
    </div>
  );
}
