import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import headerImg from './img/vosstanovlenie-ucheta.jpeg';
import checkImg from './check.svg';
import nextImg from './next.svg';

import { PageProps, SubmitRequest, useMeta } from './common';

import './style.css';


export default function VosstanovlenieUcheta(props: PageProps) {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useMeta('Восстановление учета в срок от 2 недель – закажите услуги бухгалтера', {
    description: "Оперативное восстановление бухгалтерского учета в Сыктывкаре и Республике Коми. Выполним восстановление учета в срок от 2 недель – скорость зависит от состояния дел. Цены на услуги по восстановление бухгалтерского учета от 4 000 руб. – расчет за 1 час.",
    keywords: 'восстановление учета, услуги по восстановлению бухгалтерского учета, восстановление бухгалтерского учета'
  });

  return (
    <div className="our-service-page">
      <HashLink to="/#services" className="goback">{`< Вернуться`}</HashLink>
      <div className="header">
        <div>
          <h1>Частичное и полное восстановление учета</h1>
          <span>Результат в срок от 2 недель в зависимости об объема работ</span>
          <p style={{ marginTop: '2em' }}>Обращайтесь, если понадобится восстановить бухгалтерский учет – проанализируем существующую документацию и создадим пакет недостающей. Это позволит вам избежать получения штрафов со стороны налоговых органов.</p>
        </div>
        <img src={headerImg} alt="stock" />
      </div>
      <p className="paragraph" style={{ marginTop: '2em' }}>Восстановление учета может быть частичным – когда корректировки требует только определенный период или доля отчетности, а также полным – если необходимо проверить все первичные документы, отчеты и поданные данные. Поможем в любой ситуации.</p>
      <div className="highlights">
        <div>
          <img src={checkImg} alt="check"/>
          <p>Опытные специалисты со стажем работы от 15 лет</p>
        </div>
        <div>
          <img src={checkImg} alt="check"/>
          <p>Финансовая ответственность за результат согласно договору</p>
        </div>
        <div>
          <img src={checkImg} alt="check"/>
          <p>Гарантированная конфиденциальность</p>
        </div>
        <div>
          <img src={checkImg} alt="check"/>
          <p>Удобное взаимодействие с сотрудниками</p>
        </div>
      </div>
      <SubmitRequest
        text="Узнайте сроки восстановления учета – оставьте заявку, обсудим детали"
        style={{
          marginTop: '2em',
          marginBottom: '6em'
        }}
        onClick={() => props.setSubmitRequestPopupIsOpen(true)}
      />
      <h2 className="title">Цены на услуги восстановления бухгалтерского учета</h2>
      <p className="subtitle">Фиксированная стоимость без скрытых платежей и комиссий</p>
      <p className="text">Итоговая цена услуг будет известна заранее. Сумма зависит от ряда факторов – используемой на вашем предприятии системе налогообложения, объема работ, наличия сложных процедур и т. д. Расскажем вам о нюансах.</p>
      <h2 className="title">Как обычно происходит восстановление бухгалтерского учета</h2>
      <p className="subtitle">Основные этапы процедуры:</p>
      <div className="highlights">
        <div>
          <img src={nextImg} alt="next"/>
          <p>Мониторинг текущего состояния учета на предприятии</p>
        </div>
        <div>
          <img src={nextImg} alt="next"/>
          <p>Выявление недостающих документов, их формирование</p>
        </div>
        <div>
          <img src={nextImg} alt="next"/>
          <p>Подача всей исправленной отчетности</p>
        </div>
        <div>
          <img src={nextImg} alt="next"/>
          <p>Организация дальнейшего ведения бухгалтерии</p>
        </div>
      </div>
      <SubmitRequest
        text="Закажите услуги восстановления учета – приступим к работе в этот же день или на следующий"
        style={{
          marginTop: '5em',
          marginBottom: '5em'
        }}
        onClick={() => props.setSubmitRequestPopupIsOpen(true)}
      />
    </div>
  );
}
