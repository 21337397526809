import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import arrowLeft from '../../img/arrow-left.svg';
import circle from '../../util/circle';

import img0 from './img/0.jpg';
import img1 from './img/1.jpg';
import img2 from './img/2.jpg';
import img3 from './img/3.jpg';
import img4 from './img/4.jpg';
import img5 from './img/5.jpg';

import './style.css';


interface OurServiceData {
  fileName: string,
  title: string,
  text: string
  price: number
}
type OurServicesData = {
  [key: string]: OurServiceData
}
const ourServicesModel: OurServicesData = {
  'postanovka-buhucheta': {
    title: "Постановка бухучёта",
    text: "Постановка бухгалтерского учета компании в Сыктывкаре, Республике Коми",
    price: 5000,
    fileName: img0
  },
  'buhgalterskoe-obsluzhivanie': {
    title: "Бухгалтерское обслуживание",
    text: "Комплексное бухгалтерское обслуживание 1C",
    price: 0,  // неизвестно
    fileName: img1
  },
  'vedenie-uchastkov-buhgalterii': {
    title: "Ведение участков бухгалтерии",
    text: "Услуги ведения бухгалтерского учета",
    price: 1000,
    fileName: img2
  },
  'raschet-oplaty-truda': {
    title: "Расчёт оплаты труда персонала",
    text: "Стоимость услуги бухгалтера по расчёту заработной платы и налогов с ФОТ",
    price: 3000,
    fileName: img3
  },
  'nalogovoe-konsultirovanie': {
    title: "Налоговое консультирование",
    text: "Налоговые консультации онлайн – разово и на постоянной основе",
    price: 500,
    fileName: img4
  },
  'razrabotka-uchetnoj-politiki': {
    title: "Разработка учётной политики организации",
    text: "Учетная политика организации под ключ – закажите разработку",
    price: 5000,
    fileName: img5
  },
  'organizaciya-buhgalterskogo-i-nalogovogo-ucheta': {
    title: "Организация бухгалтерского и налогового учёта",
    text: "Организация бухгалтерского учета в срок от 2 дней в Республике Коми",
    price: 0,
    fileName: img0
  },
  'vosstanovlenie-ucheta': {
    title: "Восстановление учёта",
    text: "Восстановление учета в срок от 2 недель – закажите услуги бухгалтера",
    price: 0,
    fileName: img1
  },
};


type ServicesListItemProps = {
  id: string,
  text: string,
  defaultChecked: boolean,
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

type ServicesListProps = {
  currentId: string,
  handleChange: (id: string) => void
}


type ServicesInfoStackProps = {
  currentId: string
}

function ServicesListItem(props: ServicesListItemProps) {
  const [size, setSize] = useState(window.innerWidth);

  const updateMedia = () => {
    setSize(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  });

  return (
    (size >= 768) ?

    <div className="App-page1-services-list-elem">
      <input type="radio" name="serviceName" id={props.id} defaultChecked={props.defaultChecked} onChange={props.handleChange} />
      <label htmlFor={props.id}>{props.text}</label>
      <div>
        <img src={arrowLeft} alt="" className="App-arrow-left" />
        <svg width="32" height="32" className="App-arrow-left-circle">
          {circle}
        </svg>
      </div>
    </div> :

    <div className="App-mobile-services-list-elem">
      <div className="App-page1-services-list-elem">
        <input type="radio" name="serviceName" id={props.id} defaultChecked={props.defaultChecked} onChange={props.handleChange} />
        <label htmlFor={props.id}>{props.text}</label>
      </div>
      <ServicesInfoStackItem url={props.id}
                             visible={props.defaultChecked}
                             fileName={ourServicesModel[props.id].fileName}
                             title={ourServicesModel[props.id].title}
                             text={ourServicesModel[props.id].text}
                             price={ourServicesModel[props.id].price} />
    </div>
  );
}

function ServicesList(props: ServicesListProps) {
  const items = Object.keys(ourServicesModel).map(serviceId =>
    <ServicesListItem
      key={serviceId} id={serviceId} text={ourServicesModel[serviceId].title} defaultChecked={serviceId === props.currentId}
      handleChange={e => props.handleChange(serviceId)}
    />
  );

  return (
    <div className="App-page1-services-list">
      <h1>Наши услуги</h1>
      <form>{items}</form>
    </div>
  );
}


function ServicesInfoStack(props: ServicesInfoStackProps) {
  return <ServicesInfoStackItem url={props.currentId}
                                fileName={ourServicesModel[props.currentId].fileName}
                                title={ourServicesModel[props.currentId].title}
                                text={ourServicesModel[props.currentId].text}
                                price={ourServicesModel[props.currentId].price} />;
}

interface ServicesInfoStackItemProps extends OurServiceData {
  url: string,
  visible?: boolean
}
function ServicesInfoStackItem(props: ServicesInfoStackItemProps) {
  const [size, setSize] = useState(window.innerWidth);

  const updateMedia = () => {
    setSize(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  });

  const requestCallButton = (size >= 768) ?
    <Link to={props.url} className="App-page1-services-info-footer-button">Подробнее</Link> :
    <button className="App-page1-services-info-footer-button" onClick={e => {
      e.preventDefault();
      const headerForm = document.getElementsByClassName('App-header-submit-request-container');
      if (headerForm.length) {
        headerForm[0].scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });
      }
    }}>Заказать</button>;

  return (
    (size >= 1280) ?
    <div className="App-page1-services-info">
      <img src={props.fileName} alt={props.title} />
      <div className="App-page1-services-info-footer">
        <span className="App-page1-services-info-footer-desc">{props.text}</span>
        {props.price ? <span className="App-page1-services-info-footer-price">{`от ${props.price} руб/мес.`}</span> : null}
        {requestCallButton}
      </div>
    </div> :

    (size >= 768 && size < 1280) ?
    <div className="App-page1-services-info">
      <img src={props.fileName} alt={props.title} />
      <div className="App-page1-services-info-footer">
        <span className="App-page1-services-info-footer-desc">{props.text}</span>
        <div></div>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          alignSelf: 'flex-end'
        }}>
          {props.price ? <span className="App-page1-services-info-footer-price">{`от ${props.price} руб/мес.`}</span> : null}
          {requestCallButton}
        </div>
      </div>
    </div> :

    <div style={{display: props.visible ? 'flex' : 'none'}} className="App-mobile-services-collapsible">
      <img src={props.fileName} alt={props.title} />
      <span className="App-page1-services-info-footer-desc">{props.text}</span>
      {props.price ? <span className="App-page1-services-info-footer-price">{`от ${props.price} руб/мес.`}</span> : null}
      {requestCallButton}
    </div>
  );
}

export default function OurServices() {
  const [currentId, setCurrentId] = useState(Object.keys(ourServicesModel)[0]);
  const [size, setSize] = useState(window.innerWidth);

  const updateMedia = () => {
    setSize(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  });

  return (
    (size >= 768) ?
    <div id="services">
      <div className="App-page1-content">
        <div className="App-page1-services">
          <ServicesInfoStack currentId={currentId} />
          <ServicesList currentId={currentId} handleChange={id => setCurrentId(id)} />
        </div>
      </div>
    </div> :

    <div className="App-mobile-services">
      <ServicesList currentId={currentId} handleChange={id => setCurrentId(id)} />
    </div>
  );
}
